.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  // margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
  width: 30px;
  height: 10px;
  margin: 5px;
  border-radius: 0;
  background: #fff;
}

.mainSwiper {
  width: 100%;
  height: auto;
}

.swiperSlide {
  position: relative;
}

.imageScale {
  width: 100%;
  height: auto;
  transition: transform 10s ease; /* 애니메이션을 부드럽게 합니다 */
}

.swiper-slide-active .imageScale {
  transform: scale(1.1); /* 활성 슬라이드의 이미지를 확대합니다 */
}

// SecondarySlide

.mainSwiper2 {
  width: 50%;
}

.flexSwiperItem {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
